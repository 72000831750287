import { extendTheme } from "@chakra-ui/react"
const theme = {
  styles: {
    global: {
      a: {
        textDecoration: "none !important",
      },
    },
  },
  fonts: {
    body: "DM Sans",
    heading: "DM Sans",
  },
  colors: {
    brandGreen:'#23BB72'
  }
}

export default extendTheme(theme)
